import React, { FC, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';

import { ISubNavItem } from 'gatsby-theme-nurofen/src/components/ArticleSubNav/model';
import Layout from 'gatsby-theme-nurofen/src/components/Layout';
import PageBreaker from 'gatsby-theme-nurofen/src/components/PageBreaker';
import BreadCrumbs from 'gatsby-theme-nurofen/src/components/BreadCrumbs';
import ArticleSubNav from 'gatsby-theme-nurofen/src/components/ArticleSubNav';
import ArticleTeaser from 'gatsby-theme-nurofen/src/components/ArticleTeaser';
import UniversalImage from 'gatsby-theme-nurofen/src/components/common/UniversalImage';
import Callout from 'gatsby-theme-nurofen/src/components/common/Callout';
import Explore from 'gatsby-theme-nurofen/src/components/Explore';
import { parseExploreComponent } from 'gatsby-theme-nurofen/src/templates/HubPage/parsers';
import TagLinksList from 'gatsby-theme-nurofen/src/components/common/TagLinksList';
import GroupedArticleBodySection from 'gatsby-theme-nurofen/src/components/GroupedArticleBodySection';
import ArticleExtraInfo from 'gatsby-theme-nurofen/src/components/ArticleExtraInfo';
import { removeHTMLTags } from 'gatsby-theme-nurofen/src/utils/helpers';
import ArticleSchema from 'gatsby-theme-nurofen/src/components/common/ArticleSchema';
import { parseNFButton } from 'gatsby-theme-nurofen/src/components/common/NFButton/parsers';
import FeaturedArticles from 'gatsby-theme-nurofen/src/components/FeaturedArticles';
import parseCalloutSection from 'gatsby-theme-nurofen/src/templates/AdvancedChildrenHubArticlePage/parsers';
import { CONTENT_BODY_ITEMS } from 'gatsby-theme-nurofen/src/utils/constants';

import RelatedProductsOverride from '../../components/RelatedProductsOverride';

import 'gatsby-theme-nurofen/src/templates/AdvancedChildrenHubArticlePage/AdvancedChildrenHubArticlePage.scss';

interface AdvancedChildrenHubArticlePageProps extends PageProps {
  data: AdvancedChildrenHubArticleTypes.IPage;
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[]; relatedProducts: string[] };
}

const AdvancedChildrenHubArticlePage: FC<AdvancedChildrenHubArticlePageProps> = ({
  pageContext: { breadcrumbs, relatedProducts: relatedProductsLinks },
  data: {
    allAdvancedChildrenHubArticlePage: {
      nodes: [
        {
          title,
          description,
          fullWidthBanner,
          articleBannerForPreview,
          videoPageContainer,
          authorTopInfo,
          articleAnchorNavigation,
          relatedArticles,
          articleBody,
          extraInfo,
          calloutSection,
          exploreSection,
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          seoContentType,
          lang,
          link,
          tags,
          pageName,
          relatedProductsConfig,
        },
      ],
    },
    carouselControls,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    relatedProducts,
    pageLangs: { nodes: langs },
  },
}) => {
  const [anchoredNavigation] = useState<ISubNavItem[]>(() => {
    const navItems: ISubNavItem[] = articleBody
      .filter(({ structure }) => structure === CONTENT_BODY_ITEMS.ANCHOR_SECTION)
      .map(({ properties }) => ({
        anchorName: removeHTMLTags(properties?.anchorSectionTitle || ''),
      }));

    return articleAnchorNavigation?.length > 0 ? navItems : [];
  });

  const authorTopInfoBlock =
    authorTopInfo && authorTopInfo?.length > 0 ? (
      <section className="author-top-info">
        {authorTopInfo?.[0]?.properties?.authorImage ? (
          <UniversalImage img={authorTopInfo?.[0]?.properties?.authorImage} />
        ) : null}
        <p className="author-label">{authorTopInfo?.[0]?.properties?.authorLabel}</p>
      </section>
    ) : null;

  const renderCalloutSection = useMemo(() => {
    if (!calloutSection?.length) return null;

    const {
      callout,
      tagsLists,
      featuredArticles,
      featuredArticlesCTAButton,
    } = parseCalloutSection({ ...calloutSection[0].properties });

    return (
      <Callout
        title={callout.title}
        description={callout.description}
        calloutColor={callout.color}
        tagsLists={tagsLists}
        featuredArticles={featuredArticles}
        featuredArticlesCTAButton={featuredArticlesCTAButton}
        settings={{ carouselControls }}
      />
    );
  }, [calloutSection]);

  const renderExploreSection = useMemo(() => {
    if (!exploreSection?.length) return null;
    const exploreProperties = parseExploreComponent(exploreSection?.[0]?.properties);

    return <Explore {...exploreProperties} pageName={pageName} />;
  }, [exploreSection]);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        seoContentType,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <ArticleSchema
        name={title}
        url={`${siteSettings.siteUrl}${link.replace(/^\//g, '')}`}
        description={description}
        image={fullWidthBanner?.[0]?.properties?.image?.fallbackUrl as string}
      />
      <div
        className={classNames('advanced-children-hub-article-page', {
          'advanced-children-hub-article-page-video': videoPageContainer,
        })}
      >
        <Container fluid>
          <Row>
            <Col lg={{ span: videoPageContainer ? 10 : 8, offset: videoPageContainer ? 1 : 2 }}>
              <BreadCrumbs data={breadcrumbs} />
            </Col>
          </Row>
        </Container>
        <ArticleTeaser data={{ title, body: description }} videoPageContainer={videoPageContainer}>
          <Container fluid>
            <Row>
              <Col lg={{ span: videoPageContainer ? 10 : 8, offset: videoPageContainer ? 1 : 2 }}>
                {[
                  authorTopInfoBlock,
                  <TagLinksList
                    tagsFormat="smallLabel"
                    tagsList={tags.map(({ title: name, relatedPage: link }) => ({ name, link }))}
                  />,
                ]}
              </Col>
            </Row>
          </Container>
        </ArticleTeaser>
        {!articleBannerForPreview && fullWidthBanner?.[0]?.properties ? (
          <PageBreaker
            img={fullWidthBanner?.[0]?.properties?.image}
            imgAlt={fullWidthBanner?.[0]?.properties?.imageAlt}
          />
        ) : null}
        {anchoredNavigation.length > 0 ? (
          <ArticleSubNav
            sections={anchoredNavigation}
            title={articleAnchorNavigation[0].properties?.title}
            bulletColor={
              articleAnchorNavigation[0].properties?.bulletColor?.[0]?.properties?.colorPicker
                ?.label || 'red'
            }
            ariaLabel={articleAnchorNavigation[0].properties?.sectionLandmark}
          />
        ) : null}
        <div
          className={classNames('article-body', {
            [`${articleAnchorNavigation?.[0]?.properties?.bulletColor?.[0].properties.colorPicker?.label}-default-bg`]: articleAnchorNavigation?.[0]
              ?.properties?.bulletColor,
          })}
        >
          {articleBody.map(({ properties: { anchorSectionTitle, anchorSectionContent } }) => (
            <GroupedArticleBodySection
              key={anchorSectionTitle}
              title={anchorSectionTitle}
              groupedContent={anchorSectionContent}
              pageName={pageName}
              videoPageContainer={videoPageContainer}
            />
          ))}
        </div>
        {extraInfo?.[0] ? (
          <ArticleExtraInfo
            key="ArticleExtraInfo"
            title={extraInfo[0].properties.title}
            body={extraInfo[0].properties.body}
            bgColor={extraInfo[0].properties.bgColor}
            orderedList={extraInfo[0].properties.orderedList}
          />
        ) : null}
        {relatedProducts.nodes?.length ? (
          <section className="article article-with-products">
            <RelatedProductsOverride
              title={relatedProductsConfig?.[0]?.properties?.relatedProductsTitle}
              showBtn={relatedProductsConfig?.[0]?.properties?.shopBtnsShow === '1'}
              btn={
                relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.length
                  ? parseNFButton(
                      relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.[0]?.properties
                    )
                  : undefined
              }
              products={relatedProducts.nodes}
              productsLinks={relatedProductsLinks}
              carouselControls={carouselControls}
              listingName={pageName}
            />
          </section>
        ) : null}
        {relatedArticles?.[0]?.properties ? (
          <FeaturedArticles
            bgColor={relatedArticles[0].properties.featuredArticlesBgColor}
            title={relatedArticles[0].properties.featuredArticlesSectionTitle}
            subtitle={relatedArticles[0].properties.featuredArticlesSectionSubtitle || ''}
            btn={
              relatedArticles[0].properties.featuredArticlesCTAButtonData?.length
                ? {
                    ...parseNFButton(
                      relatedArticles[0].properties.featuredArticlesCTAButtonData[0].properties
                    ),
                  }
                : undefined
            }
            articles={relatedArticles[0].properties.featuredArticlesData}
            mode={relatedArticles[0].properties.featuredArticlesSelectedArticleMode}
            carouselControls={carouselControls}
          />
        ) : null}
        {renderCalloutSection}
        {renderExploreSection}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!, $pageIdRegex: String, $relatedProducts: [String]) {
    allAdvancedChildrenHubArticlePage(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        lang
        link
        ...FragmentSeo
        title
        pageName
        description
        articleBannerForPreview
        isVideoPage
        videoPageContainer
        videoPageContainer
        fullWidthBanner {
          properties {
            image {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              focalPoint {
                x
                y
              }
            }
            imageAlt
          }
        }
        articleAnchorNavigation {
          properties {
            title
            bulletColor {
              ...FragmentColorProps
            }
            sectionLandmark
          }
        }
        authorTopInfo {
          properties {
            authorLabel
            authorImage {
              svg {
                content
              }
            }
          }
        }
        relatedArticles {
          properties {
            ...FragmentFeaturedArticles
          }
        }
        relatedProductsConfig {
          properties {
            ...FragmentRelatedProductsConfig
          }
        }
        articleBody {
          structure
          properties {
            anchorSectionTitle
            anchorSectionContent {
              structure
              properties {
                text
                table
                quote
                quoteBackgroundColor {
                  properties {
                    colorPicker {
                      label
                    }
                  }
                }
                imgAlt
                featuredItemsSection {
                  properties {
                    ...FragmentFeaturedItemsSection
                  }
                }
                featuredImage {
                  fallbackUrl
                  gatsbyImage {
                    childImageSharp {
                      fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                ...FragmentExplore
                ...FragmentOrderedListI
                ...FragmentAccordionCommon
                videoBlock {
                  structure
                  properties {
                    ...FragmentVideoBlock
                  }
                }
                videoBottomAccordion {
                  structure
                  properties {
                    ...FragmentAccordionCommon
                  }
                }
                ...FragmentVideoBlocksWithCarouselSection
              }
            }
          }
        }
        extraInfo {
          ...FragmentArticleExtraInfo
        }
        calloutSection {
          properties {
            ...FragmentAdvancedChildrenHubCalloutSection
          }
        }
        exploreSection {
          properties {
            ...FragmentExplore
          }
        }
        tags {
          title
          relatedPage
        }
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    relatedProducts: allUmbracoProduct(
      filter: { link: { nin: [$link], in: $relatedProducts }, lang: { eq: $lang } }
      limit: 6
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    pageLangs: allAdvancedChildrenHubArticlePage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default AdvancedChildrenHubArticlePage;
